<template>
  <div class="bottomColor">
    <div class="borderBottom">
      <span class="s16size">关联商品</span>
    </div>
    <!-- 商品图片展示 -->
  <div class="maxHeightBox">
      <div
      style="margin-top:20px"
      v-for="(item,index) in infoData && infoData.join_goods || []"
      :key="index"
    >
      <div>
        <el-image @click="details(item)" style="width: 290px; height: 290px" :src="item.cover_url"></el-image>
      </div>
      <el-link @click="details(item)" :underline="false">
        <p class="yichu">{{item.name||'--'}}</p>
      </el-link>
      <p>
        <el-link @click="details(item)" :underline="false" class="red_16size">
          <p>¥{{item.item_sku && item.item_sku.sale_price}}</p>
        </el-link>
      </p>
      <el-divider></el-divider>
    </div>
  </div>
  </div>
</template>

<script>
export default {
  props: {
    infoData: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      list: [{}, {}, {}, {}, {}, {}]
    };
  },
  methods: {
    details(item) {
      this.$parent.$parent.$parent.query(item);
      // console.log(this.$parent.$parent.$parent)
    }
  }
};
</script>

<style lang="scss" scoped>
.bottomColor {
  background-color: #fff;
  padding: 20px 25px;

  .maxHeightBox {
    max-height: 500px;
    overflow: scroll;
  }
}
.borderBottom {
  display: inline-block;
  border-bottom: 3px solid #f74848;
}
::-webkit-scrollbar {
  width: 0px;
}

::-webkit-scrollbar-track {
  background-color: none;
}

::-webkit-scrollbar-thumb {
  background-color: none;
}

::-webkit-scrollbar-thumb:hover {
  background-color: none;
}

::-webkit-scrollbar-thumb:active {
  background-color: none;
}

::v-deep .borderBottom::-webkit-scrollbar {
  display: none;
}
.s16size {
  font-size: 18px;
}
.red_16size {
  font-size: 16px;
  color: #f74848;
  font-weight: bold;
}
p {
  margin-top: 5px;
}
.yichu {
  width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
</style>