<template>
    <div class="bottomColor">
         <div class="borderBottom">
            <span class="s16size">商品详情</span>
        </div>
        <div class="bgc" >
            <!-- <div v-html="infoData."></div> -->
            html
        </div>
    </div>
</template>

<script>
    export default {
       props: {
    infoData: {
      type: Object,
      default: {}
    }
  },
        
    }
</script>

<style lang="scss" scoped>
.bottomColor {
  background-color: #fff;
  padding: 0 25px;
  height: 500px;
}
.borderBottom {
  display: inline-block;
  border-top: 3px solid #f74848;
}
.s16size {
  font-size: 18px;
}
.bgc{
    margin-top: 10px;
    background-color: #F5F5F5 ;
    padding: 10px 15px;
}
</style>