<template>
<!-- 订单详情页 -->
    <div class="centerBox">
      
        <el-row type="flex" :gutter="10" justify="space-between">
            <!-- 订单详情 提交订单 -->
            <el-col :span="18">
                <infoPlaceOrder :infoData='infoData' @guige='guige'/>
            </el-col>
            <!-- 公司详情 -->
            <el-col :span="6">
                <infoCompany :infoData='infoData'/>
            </el-col>
        </el-row>
        <el-row style="margin-top:10px" type="flex" :gutter="10" justify="space-between">
            <el-col :span='7'>
                <!-- 关联商品 -->
                <relation  :infoData='infoData'/>
            </el-col>
            <!-- 商品详情 -->
            <el-col :span='17'>
                <info  :infoData='infoData'/>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import infoPlaceOrder from "./components/infoPlaceOrder.vue";
import infoCompany from "./components/infoCompany.vue";
import relation from "./components/relation.vue";
import info from "./components/info.vue";
export default {
  
  components: {
    infoPlaceOrder,
    infoCompany,
    relation,
    info
  },
  data(){
    return {
      infoData:null
    }
  },
  created () {
    // console.log(this.$route.query.id)
    this.query()
  },
  methods: {
      guige(val){
        // console.log(val)
        this.query(val)
      },
    query(data){
      console.log(data)
      let params ={
        item_sku_id:this.$route.query.item_sku_id,
        app_goods_id:this.$route.query.app_goods_id
      }
      if(data){
        params.attr_symbol_path_id = data
      }
      this.$axios.postJSON('/app_shop/app_market_detail',params).then(res=>{
        if(res.code == 200){
          console.log(res)
          this.infoData = res.data
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.centerBox {
  width: 1200px;
  margin: 0 auto;
}
.flexBox {
  display: flex;
  justify-content: space-between;
}
</style>