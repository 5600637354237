<template>
<!-- 公司信息 -->
    <div class="infoWidth">
        <!-- 标题 -->
        <el-row :gutter="30" style="margin-top:10px">
            <el-col :span="6">
                <div>
                    <el-image
                        style="width: 64px; height: 36px"
                        :src="'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'"
                    ></el-image>
                </div>
            </el-col>
            <el-col align="center" :span="18">
                <p class="red_16size">{{'翡云科技'}}</p>
            </el-col>
        </el-row>
        <el-divider></el-divider>
        <!-- 公司 -->
        <h4>{{'四川翡云科技有限公司'}}</h4>
        <p style="color:#999;margin-top:10px">
            <span style="margin-right:5px">所在地址:</span>
            {{'四川省成都市口金牛区'}}
        </p>
        <el-divider></el-divider>
        <div style="height:130px">
            <span style="color:#666">{{'公司简介公司简介公司简介公司简介公司简介公司简介公司简介公司简介'}}</span>
        </div>
        <el-divider></el-divider>
        <div>
            <el-image
                style="width: 64px; height: 36px"
                :src="'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'"
            ></el-image>
        </div>
       <p style="color:#999"><span>联系电话：</span><span>{{'026-132442'}}</span></p>
    </div>
</template>

<script>
export default {
    props:{
        infoData:{
            type:Object,
            default:{}
        }
    },
    data() {
        return {
            
        }
    },
};
</script>

<style lang="scss" scoped>
.infoWidth {
  height: 440px;
  background-color: #fff;
  padding: 15px;
}
.red_16size {
  font-size: 20px;
  color: #f74848;
  font-weight: bold;
}
</style>