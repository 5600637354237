<template>
  <!-- 商品购买 -->
  <div class="infoWidth">
    <el-row :gutter="50">
      <!-- 图片 -->
      <el-col :span="8">
        <div>
          <!-- <el-image style="width: 274px; height: 274px" :src=" infoData && infoData.cover_url"></el-image> -->
          <!-- 轮播图 -->
          <el-carousel indicator-position="none" height="274px" style="width: 274px; height: 274px">
            <el-carousel-item v-for="(item, index) in infoData && infoData.images_url" :key="index">
              <el-image style="width: 274px; height: 274px" :src="item"></el-image>
            </el-carousel-item>
          </el-carousel>
          <!-- 切换图片 -->
          <div class="tapPic">
            <el-image
              v-for="(item, index) in infoData && infoData.images_url"
              :key="index"
              :src="item"
              style="width: 64px; height: 64px"
            ></el-image>
          </div>
        </div>
      </el-col>
      <!-- 文字区域 -->
      <el-col :span="16">
        <h2>{{ (infoData && infoData.name) || "--" }}</h2>
        <p>{{ (infoData && infoData.sub_title) || "--" }}</p>
        <!-- 价格详情 -->
        <div class="priceInfo">
          <div>
            <!-- 销售价格 -->
            <p>
              <span>销售价</span>
              <b style="margin-right:10px;color:red">¥</b>
              <span class="bigRed">{{ (infoData && infoData.sku_info.sale_price) || "--" }}</span>
              <b>套</b>
            </p>
            <!-- 划线价 -->
            <p>
              <span>划线价</span>
              <s>¥{{ (infoData && infoData.sku_info.base_price) || "--" }}</s>
            </p>
            <!-- 商品编号 -->
            <p>
              <span>商品编号</span>
              <span>{{ (infoData && infoData.sku_info.serial_number) || "--" }}</span>
            </p>
            <!-- 平台SKU -->
            <p>
              <span>平台SKU</span>
              <span>{{ (infoData && infoData.sku_info.sku) || "--" }}</span>
            </p>
          </div>
          <div>
            <div>
              <el-image
                style="width: 100px; height: 100px"
                :src="'https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg'"
              ></el-image>
            </div>
            <p style="text-align:center">手机扫描二维码</p>
            <p style="text-align:center">即可浏览商品</p>
          </div>
        </div>
        <!-- 选项 -->
        <el-form style="margin-top:10px" ref="form" size="mini">
          <el-form-item v-for="(item, index) in (infoData && infoData.sku_list) || []" :key="index">
            <span slot="label">{{ item.attr_name }}</span>
            <div style="margin-left:30px" class="dan">
              <div
                v-for="(k, index2) in item.item_attr_val"
                :key="index2"
                @click="choseOne(item, k)"
                :class="k.checked == 1 ? 'chosed' : 'names'"
              >
                {{ k.attr_value || "--" }}
              </div>
            </div>
          </el-form-item>
        </el-form>
        <!-- 立即购买 -->
        <div style="text-align:center">
          <el-button @click="shopping" type="danger">立即购买</el-button>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: {
    infoData: {
      type: Object,
      default: {},
    },
  },
  watch: {
    infoData(val) {
      this.newArr = val.sku_list.slice(1);
      console.log(this.newArr);
    },
  },
  data() {
    return {
      newArr: [],
    };
  },
  methods: {
    choseOne(item, k) {
      item.item_attr_val.forEach(i => {
        i.checked = 0;
      });
      k.checked = 1;
      let arr = [];
      this.infoData.sku_list.forEach(i => {
        i.item_attr_val.forEach(n => {
          if (n.checked == 1) {
            arr.push(n.id);
          }
        });
      });
      this.$emit('guige',arr)
    },
    choseAll(item, index) {
      this.$set(item, "isChose", true);
      this.infoData.sku_list[index + 1].item_attr_val.forEach(i => {
        if (i.isChose) {
          if (i.id !== item.id) {
            i.isChose = false;
          }
        }
      });
    },
    shopping() {
      this.$router.push({
        path: "/pay",
        query: { id: this.infoData.sku_info.id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dan {
  display: flex;
  padding: 2 0px 0;
}
.names {
  display: inline-block;
  min-width: 66px;
  height: 26px;
  border: 1px #333 solid;
  margin: 0 15px 15px 0;
  text-align: center;
  cursor: pointer;
  padding: 0 8px;
  border-radius: 3px;
  line-height: 26px;
}
.chosed {
  display: inline-block;
  min-width: 66px;
  height: 26px;
  border: 2px red solid;
  color: red;
  margin: 0 15px 15px 0;
  padding: 0 8px;
  text-align: center;
  cursor: pointer;
  border-radius: 3px;
  line-height: 26px;
}

.infoWidth {
  // width: 919px;
  height: 440px;
  background-color: #fff;
  padding: 15px;
}
.priceInfo {
  margin-top: 15px;
  width: 100%;
  background-color: #fafafa;
  padding: 15px 20px 10px 15px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;

  p {
    color: #666;
    margin-bottom: 15px;
    span {
      margin-right: 20px;
    }
  }
}
.bigRed {
  color: #f74848;
  font-size: 28px;
  font-weight: bold;
}
.tapPic {
  width: 274px;
  height: 64px;
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
}
</style>
